/* You can add global styles to this file, and also import other style files */
@import '~mapbox-gl/dist/mapbox-gl.css';
// @import '~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
// @import '@angular/material/theming';
// @import './stylesCustomTheme.scss';

// @font-face {
//   font-family: 'FuturaBook';
//   src: url('./assets/fonts/futura/futurabk.woff2') format('woff2'),
//     url('./assets/fonts/futura/futurabk.eot');
// }

// @font-face {
//   font-family: 'FuturaMedium';
//   src: url('./assets/fonts/futura/futuramedium.woff2') format('woff2'),
//     url('./assets/fonts/futura/futuramedium.eot');
// }

// @font-face {
//   font-family: 'FuturaMediumBold';
//   src: url('./assets/fonts/futura/futuramedium_bold.woff2') format('woff2'),
//     url('./assets/fonts/futura/futuramedium_bold.eot');
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('./assets/fonts/calibre/calibre-web-regular.woff2') format('woff2'),
//     url('./assets/fonts/calibre/calibre-web-regular.eot');
// }

// @font-face {
//   font-family: 'Calibre Italics';
//   src: url('./assets/fonts/calibre/calibre-web-regular-italic.woff2')
//       format('woff2'),
//     url('./assets/fonts/calibre/calibre-web-regular-italic.eot');
// }

// @font-face {
//   font-family: 'Calibre Bold';
//   src: url('./assets/fonts/calibre/calibre-web-bold.woff2') format('woff2'),
//     url('./assets/fonts/calibre/calibre-web-bold.eot');
// }

// @font-face {
//   font-family: 'Calibre Semibold';
//   src: url('./assets/fonts/calibre/calibre-web-semibold.woff2') format('woff2'),
//     url('./assets/fonts/calibre/calibre-web-semibold.eot');
// }

// @font-face {
//   font-family: 'Calibre Light';
//   src: url('./assets/fonts/calibre/calibre-web-light.woff2') format('woff2'),
//     url('./assets/fonts/calibre/calibre-web-light.eot');
// }

// @font-face {
//   font-family: 'Financier Display';
//   src: url('./assets/fonts/financier/financier-display-web-semibold.woff2')
//       format('woff2'),
//     url('./assets/fonts/financier/financier-display-web-semibold.eot');
// }

// .mat-card-header-text {
//   margin: 0 32px !important;
// }

.mat-select-value {
  font-family: "Calibri" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

// .mat-select-trigger {
//   height: 32px !important;
// }

// .mat-select-placeholder {
//   color: #3a3a3a !important;
//   font-family: FuturaBook, sans-serif !important;
//   font-size: 13px !important;
// }

// .mat-option-text {
//   color: #262626 !important;
//   font-family: 'Calibre', sans-serif !important;
//   font-size: 16px !important;
// }


/*Emerald drop down*/
/*Mat-form-field-appearance-outline styles*/
.mat-form-field-appearance-outline .mat-select-value {
  padding-left: 6px;
  color: #262626 !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}

//Default color of Label, Outline & Arrow
.mat-form-field-appearance-outline .mat-form-field-label {
  color: rgba(26, 26, 26, 0.6) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(26, 26, 26, 0.6) !important;
  background: #fff;
}

.mat-form-field-appearance-outline .mat-select-arrow {
  color: rgba(26, 26, 26, 0.6) !important;
}

// Hover state color of Label, Outline & Arrow
.mat-form-field-appearance-outline:hover .mat-form-field-label {
  color: #1A1A1A !important;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0,0,0,.38) !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-invalid):hover .mat-form-field-outline {
  color: #1A1A1A !important;
  opacity: 1 !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled):hover .mat-form-field-outline-thick {
  opacity: 0 !important;
}

.mat-form-field-appearance-outline:hover .mat-select-arrow {
  color: #1A1A1A !important;
}

// focused state color of Label, Outline & Arrow
.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color:#003f2d !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-invalid).mat-focused .mat-form-field-outline {
  color:#003f2d !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-select-arrow {
  color:#003f2d !important;
}

// Error default style
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-label {
  color: #FF543E!important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {
  color: #FF543E!important;
  opacity: 1 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
  opacity: 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid .mat-select-arrow {
  color: #FF543E!important;
}

// Error Hover style
.mat-form-field-appearance-outline.mat-form-field-invalid:hover  .mat-form-field-label {
  color: #A03530!important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid:hover  .mat-form-field-outline {
  color: #A03530 !important;
  opacity: 1 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid:hover .mat-form-field-outline-thick {
  opacity: 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid:hover  .mat-select-arrow {
  color: #A03530!important;
}

// Error Focused style
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-focused  .mat-form-field-label {
  color: #FF543E !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-focused  .mat-form-field-outline {
  opacity: 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-focused .mat-form-field-outline-thick {
  color: #FF543E !important;
  opacity: 1 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-focused  .mat-select-arrow {
  color: #FF543E!important;
}
.mat-form-field-appearance-outline.mat-form-field-disabled, .mat-form-field-appearance-outline.mat-form-field-disabled:hover{
  cursor:not-allowed;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  // background: rgba(26, 26, 26, 0.12) !important;
  color: rgba(26, 26, 26, 0.46) !important;
  cursor: not-allowed!important;
}
.mat-form-field-appearance-outline.mat-form-field-disabled:hover .mat-form-field-outline {
  // background: rgba(26, 26, 26, 0.12) !important;
  color: rgba(26, 26, 26, 0.46) !important;
  opacity: 0.5 !important;
  cursor: not-allowed!important;
}
.mat-select-disabled .mat-select-trigger {cursor:not-allowed}
.mat-input-element:disabled { color: rgba(0,0,0,.38); cursor: not-allowed; }
.mat-form-field-appearance-outline.mat-form-field-disabled .dropdown-icon { color: rgba(0,0,0,.38); cursor: not-allowed; }
button,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
  border-radius: 0;
}

.glv-button {
  min-width: 80px;
  height: 32px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
  border: solid 2px #00a657;
  background: #ffffff;
  font-family: FuturaMediumBold, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #00a657;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  text-transform: uppercase;
  outline: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
}

.glv-button:hover {
  background-color: #00a657;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
}

.glv-button.clicked {
  background-color: #00a657;

  color: #fff;
  border: solid 2px rgba(0, 0, 0, 0);
  outline: none;
}

.glv-button--primary.clicked {
  border: solid 2px #00a657;
  background-color: #ffffff;
  color: #00a657;
  outline: none;
}

.glv-button--primary {
  background-color: #00a657;
  border: none;
  color: #fff;
  outline: none;
}

.glv-button--primary:hover {
  background-color: #69be28;
  color: #fff;
}

.glv-button--primary .bp--btn__icon {
  fill: #fff;
}

//New Emerald Style buttons
.glv-button-emerald {
  width: 120px;
  height: 36px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #003F2D;
  background: #ffffff;
  font-family: 'Calibre', sans-serif;
  font-size: 15px;
  // font-weight: bold;
  text-align: center;
  color: #003F2D;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  outline: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  border-radius: 4px;
}

.glv-button-emerald:hover {
  background-color:rgba(0, 63, 45, 0.08);
  border: solid 1px #003F2D;
  color: #003F2D;
  outline: none;
  cursor: pointer;
}

.glv-button-emerald.clicked {
  background-color: #fff;
  color: #003F2D;
  border: solid 2px #003F2D;
  outline: none;
}

 .glv-button-emerald:focus{
    border: solid 1px #ffffff;
    outline: 2px solid #003F2D;
    border-radius: 5px;
  }

.glv-button-emerald--primary {
  background-color: #003F2D;
  border: none;
  color: #fff;
  outline: none;
}

.glv-button-emerald--primary.clicked {
  border: solid 2px #003F2D !important;
  background-color: #003F2D;
  color: #ffffff;
  outline: none;
}

.glv-button-emerald--primary:hover {
  background-color: #003F2D;
  opacity: 0.8;
  color: #fff;
  border: none;
}

.glv-button-emerald--primary .bp--btn__icon {
  fill: #fff;
}

.glv-button--disabled {
  background-color: #dfdfdf;
  border: none;
  color: #fff;
  outline: none;
  pointer-events: none;
}

.glv-button--disabled:hover {
  background-color: #dfdfdf;
  color: #fff;
  cursor: default;
}

.disabledText {
  color: #dfdfdf !important;
  cursor: default !important;
  pointer-events: none;
}

.disabledText-white {
  color: #ffffff !important;
  cursor: default !important;
  pointer-events: none;
}

.formError {
  border: solid 1px red !important;
  color: red !important;
}

.formError:focus {
  border-left: red solid 3px !important;
  margin-left: -2px !important;
  outline: none !important;
}

.formError .mat-select-value {
  color: red !important;
}

.formError .mat-select-arrow {
  color: red !important;
}

.mapboxgl-ctrl-top-right {
  z-index: 20 !important;
  right: 10px !important;
  top: 10px !important;
  width: 32px !important;
  transition: {
    duration: 0.4s;
    timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.mapboxgl-ctrl-top-right_panel-open {
  transform: translate3d(-315px, 0, 0) !important;
}

.mapboxgl-ctrl-group {
  margin: 0 !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5) !important;
}

.mapboxgl-ctrl-icon {
  padding: 0 !important;
  height: 32px !important;
  width: 32px !important;
}

.customCtrlBtn {
  width: 32px;
  height: 32px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background: #fff;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Ccircle style='fill:%23333333;' cx='5' cy='5' r='2'/%3E %3Ccircle style='fill:%23333333;' cx='15' cy='5' r='2'/%3E %3Ccircle style='fill:%23333333;' cx='5' cy='15' r='2'/%3E %3Ccircle style='fill:%23333333;' cx='15' cy='15' r='2'/%3E %3C/svg%3E");
}

.customCtrlBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.customCtrlDiv {
  position: relative;
  left: 10px;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5) !important;
}

::selection {
  background: #D7E5F2;
  color: #1A1A1A;
}

::-moz-selection {
  background: #69be28;
  color: #ffffff;
}

.material-icons.md-20 {
  font-size: 20px;
  height: 20px;
  color: #003f2d !important;
}

.mat-menu-item {
  font-family: Calibre, sans-serif !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
}

.mat-sidenav {
  border: none !important;
}

.spacer {
  flex: 1 1 auto;
}

.mat-toolbar-multiple-rows {
  min-height: 0 !important;
}

.mat-toolbar-row {
  padding: 0 16px 0 32px !important;
}

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
  height: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #777777;
  border-radius: 16px;
  border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3a3a3a;
  border-radius: 16px;
  border: 5px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

::-ms-input-placeholder {
  font-style: italic;
}

.formError::-webkit-input-placeholder {
  color: red !important;
}

.formError::-moz-placeholder {
  color: red !important;
}

.formError::-ms-input-placeholder {
  color: red !important;
}

.addPointCursor {
  cursor: -webkit-image-set(
        url('/assets/Images/add.svg') 1x,
        url('/assets/Images/add2.svg') 2x
      )
      16 64,
    crosshair;
}

.addTextBoxCursor {
  cursor: -webkit-image-set(url('/assets/Images/addTextCursor.svg') 1x) 17 12,
    crosshair;
}

body.waiting * {
  cursor: wait;
}

body.waitingPoint * {
  cursor: wait;
  pointer-events: none;
}

body.pointing * {
  cursor: pointer;
}

body.grabbing * {
  cursor: grabbing !important;
}

.mapboxgl-popup {
  z-index: 200 !important;
}

.mapboxgl-popup-content {
  padding: 0 !important;
  // border-radius: 4px !important;
}

.mat-grid-tile .mat-figure {
  justify-content: left !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.1));
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.1));
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  filter: drop-shadow(1px 0 2px rgba(0, 0, 0, 0.1));
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.1));
}

.modal-style-class {
  mat-dialog-container {
    padding: 0;
    border-radius: 0;
    min-width: 1000px;
    border-top: 4px solid #00a657;
    overflow: hidden;
  }
  mat-dialog-content {
    padding: 0;
    margin: 0;
  }
}

/* keep dialog from pushing body of page to the left to accommodate scrollbar area */
.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

mat-paginator.mh-paginator
  > div.mat-paginator-outer-container
  > div.mat-paginator-container {
  justify-content: space-between !important;
  min-height: 35px !important;
}

.mh-paginator ::ng-deep .mat-select-arrow {
  color: #00a657 !important;
}

.glv-snackbar {
  background: #005596 !important;
  color: white !important;
  z-index: 500;
}

.glv-snackbar-error {
  background: #ce152c !important;
  color: white !important;
  z-index: 500;
}

.mat-simple-snackbar-action {
  color: white !important;
}

.disabled.mat-select-arrow {
  color: red;
}

.mat-expansion-panel-header.mat-expanded:focus {
  background: #fff !important;
}

.mat-expansion-panel-header.mat-expanded:hover {
  
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  display: flex;
  flex-grow: 1;
  margin-right: 16px !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-header {
  cursor: default !important;
}

.mat-expansion-indicator {
  cursor: pointer !important;
}

.mat-expansion-panel {
  overflow: visible !important;
  border-bottom: 1px solid #f5f7f7;
}

// .mapboxgl-ctrl-top-right {
//   width: 32px !important;
// }

.modal-title-div {
  background-color: #f9f9fc;
  padding: 24px 32px;
  display: flex;
}

.modal-title-text {
  font-family: FuturaMediumBold, sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: normal;
  color: #3a3a3a;
}

.mat-drawer-inner-container {
  // overflow: hidden !important;
  display: flex;
    flex-direction: column;
    flex: 1;
}

.mat-checkbox-frame {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 0px !important;
  box-sizing: border-box;
  pointer-events: none;
  border: solid 1px #c2c2c8 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #003F2D !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #003F2D !important;
}

.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #003F2D !important;
}

.mat-slider-thumb-label-text {
  color: white !important;
}

.mat-slider-track-background {
  background-color: #cbeecb !important;
}

.mat-slider-thumb {
  background-color: #69be28 !important;
  border: none !important;
}

.mat-slider-thumb-label {
  background-color: #69be28 !important;
  border: none !important;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-step-header .mat-step-label {
  font-family: Calibre, sans-serif !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #4d4d4d !important;
}

.mat-step-header {
  pointer-events: none !important;
}

.mat-step-header:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

.mat-step-label-selected {
  color: #003F2D !important;
}

.mat-step-icon-selected {
  background-color: #003F2D !important;
}

.mat-step-label .mat-step-label-active {
  display: flex;
  height: 48px !important;
  overflow: hidden;
  align-items: center;
  padding: 0 24px;
}

.mat-horizontal-content-container {
  padding: 0 32px 32px 32px !important;
  height: 384px !important;
}

.mat-horizontal-stepper-header {
  padding: 0 32px !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  display: block;
  height: 100% !important;
}

.mat-horizontal-stepper-content {
  height: 416px;
}

.mat-radio-container {
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-outer-circle {
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-inner-circle {
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-button .mat-radio-ripple {
  height: 32px; /*double of your required circle radius*/
  width: 32px; /*double of your required circle radius*/
  left: calc(50% - 16px); /*'10px'-same as your required circle radius*/
  top: calc(50% - 16px); /*'10px'-same as your required circle radius*/
}

.mapboxgl-marker:hover {
  cursor: pointer !important;
}

.polygonPointer {
  cursor: pointer;
}

.mapboxgl-ctrl-compass,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-zoom-in {
  padding: 0 !important;
  height: 32px !important;
  width: 32px !important;
  display: block;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
  padding: 0 !important;
}

.mat-button-toggle {
  white-space: nowrap;
  position: relative;
  width: 36px;
  height: 36px;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white !important;
}

.mat-button-toggle-checked {
  background-color: #003F2D !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #003F2D !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 63, 45, 0.5) !important;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.float-right {
  float: right;
}

.reset-padding-lr {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mat-slide-toggle-content {
  font-family: FuturaBook, sans-serif !important;
}

.mat-grid-tile-content {
  justify-content: left !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #003F2D !important;
}

.mat-select-value-text {
  font-family: 'Calibre', sans-serif !important;
}

// emerald fonts
.eds-h5 {
  font-family: 'Calibre-R';
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #1A1A1A;
}
.eds-h3 {
  font-family: 'Calibre-R';
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
}

.eds-h6 {
  font-family: 'Calibre-R';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1A1A1A;
}
.eds-body1 {
  font-family: 'Calibre-R';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.eds-body2 {
  font-family: 'Calibre-R';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(26, 26, 26, 0.6);
}
.subtitle2 {
  font-family: 'Calibre-R';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.financier-h5 {
  font-family: "Financier Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.dark-text { color: #1A1A1A; }
.primary-green-text { color: #003F2D; }
.text-500 { font-weight: 500; }
.text-600 { font-weight: 600; }
.gap-1 { gap: 4px };
.gap-2 { gap: 8px };
.gap-3 { gap: 16px };
.gap-4 { gap: 24px };

.chip{
  font-family: 'Calibre-R' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #1A1A1A;
}

.mat-calendar-body-selected {
  background-color: #003F2D;
}
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #FFFFFF;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color:#e9ecef;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border: 1px solid #1a1a1a;
  background: #FFFFFF;
}
.mat-datepicker-toggle-active .mat-focus-indicator svg {
  fill: #003F2D;
}

.mat-chip.mat-primary {
  background-color: #003F2D1A;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #003F2D;
}

a.hyperlink {
  color: #003f2d;
  text-decoration: underline;
  text-decoration-color: #3d6d5f;
  cursor: pointer;
}

a.hover {
  text-decoration: none;
}