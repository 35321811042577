
// themes
$primary_light: #003F2D;
$secondary_light: #195141;
$secondary_2_light: #E0E8E6;


$primary_dark: #17E88F;


$textColor_white: #FFFFFF;

$background_gray: #778F9C;
$background_primary_dark: #435354;

$fontFamily_Calibre: 'Calibre-R';
$fontFamily_Mono: 'Space Mono';




// mixin that enables css variables in light mode
@mixin lighten() {
  --primaryButton_background: #{$primary_light};
  --primaryButton_textColor: #{$textColor_white};
  --fontFamily: #{$fontFamily_Calibre};
  --primaryButton2_background: #{$primary_light};
  --primaryButton2_border: transparent;
  --primaryButton2_textColor: #{$textColor_white};
}

// mixin that enables css variables in dark mode
@mixin darken() {
    --primaryButton_background: #{$primary_dark};
    --primaryButton_textColor: #0B0E0E;
    --fontFamily: #{$fontFamily_Mono};
    --primaryButton2_background: transparent;
    --primaryButton2_border: #{$primary_dark};
    --primaryButton2_textColor: #{$primary_dark};
  }

@mixin geoskill_light() {
  --banner-background: #{$background_gray};
}

@mixin geoskill_dark() {
  --banner-background: #{$background_primary_dark};
}

body.dark {
  @include darken();
  @include geoskill_dark();
}
body {
  @include lighten();
  @include geoskill_light();
}

.primary-theme {
  background: var(--primaryButton_background);
  color: var(--primaryButton_textColor);
  font-family: var(--fontFamily);
}

.primary-theme-2 {
  background-color: var(--primaryButton2_background) !important;
  border-color: var(--primaryButton2_border) !important;
  font-family: var(--fontFamily);
}

body.dark .button-icon .icon-style{
  color: #17E88F;
}