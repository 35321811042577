//NOTE: Do not change the order of the scss loads.  One depends on the other.
// @use "@emerald/ngstyle";
// @include ngstyle.init();
@import './_variables.scss';
@import "bootstrap/scss/bootstrap.scss";
@import './_bootswatch.scss';
// regular style toast 
@import '~ngx-toastr/toastr';
@import './matCustomTheme.scss';
//@import '~material-design-icons/iconfont/material-icons.css';
//@import '@angular/material/prebuilt-themes/indigo-pink.css";
// $fa-font-path: "~font-awesome/fonts";
// @import "node_modules/font-awesome/scss/font-awesome.scss";


:root {
    --header-height: 72px;
}
.form-control:focus {
    border-color: #00733c;
    box-shadow: 0 0 0 0.2rem rgba(0, 115, 60, 0.3);
}

.top-margin {
    margin-top: 20px
}

.control-width {
    width: 380px;
}

//
//Below for Angular Material
//
.apbPanelClass .mat-dialog-container {
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.apbPanelClass .mat-dialog-title {
    padding: 15px 40px 15px 15px;
    position: relative;
    margin-top: 0 !important;
    margin: 0 !important;
    border-bottom: 1px solid #e5e5e5;
    background: #eee;
}

.form-section {
    min-width: 150px;
    max-width: 100% !important;
    width: 100%;
    padding: 10px 0px !important;
}

.header-section {
    width: 100%;
}

.mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 16px 0px 8px 0px !important;
    gap: 16px;
}

.mat-dialog-content {
    padding: 0 24px !important;
}

.popup-close {
    float: right;
    background: transparent !important;
    box-shadow: none !important;
    margin: 0px 0px!important;
    padding: 0px !important;
    position: absolute!important;
    top: 50%;
    right: 0;
    float: none;
    margin: 0!important;
    min-width: 40px!important;
    transform: translateY(-50%)!important;
}

.example-full-width {
    width: 100%;
}

select.vendor {
    -webkit-appearance: menulist-button;
}

.margin-10minus {
    margin-left: -10px;
}

.padding-bottom0 {
    padding-bottom: 0px;
}

.mat-dialog-container {
    min-width: 650px;
    border-radius: 0 !important;
}

.add_icon {
    position: relative;
    top: 4px;
    font-size: 20px;
}

.wd_95per {
    width: 95% !important
}

.mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none !important;
}

.margin_right5 {
    margin-right: 5px;
}
.mat-expansion-panel-header {
    height: 64px;
}
.mat-expansion-panel-header-title {
    color: #1A1A1A;
}


/*15th Jan*/

.receivingEmails_custom {
    display: flex;
}

.receivingEmails_custom i {
    margin-top: 5px;
}

.ml_10 {
    margin-left: 10px;
}

.ml_5 {
    margin-left: 5px;
}

.ml_25 {
    margin-left: 25px;
}

.ml_50 {
    margin-left: 50px;
}

.ml_70 {
    margin-left: 70px;
}

.margin-right10 {
    margin-right: 10px !important;
}

.tooltip-inner {
    color: rgba(0, 0, 0, .87) !important;
    background-color: #dddddd !important;
    position: fixed !important;
    max-width: 100% !important;
}

.ck-editor__editable {
    min-height: 250px;
    max-height: 400px;
}

.comment-area {
    width: 100%;
    margin-bottom: 0px;
    outline: 1px groove #666;
    outline-offset: 0px;
}

.comment-area p {
    margin-block-start: 0px !important;
}

.attachment {
    margin-bottom: 15px;
}

.sortActiveColumn {
    display: inline;
}

td.mat-cell i.font-s-12 {
    font-size: 12px !important;
}

.mat-checkbox-ntt {
    text-transform: none!important;
}

.error-message {
    color: #E05c65;
}

.cmnFilter {
    padding-top: 8px;
    float: right;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #003F2D;
}

tr.mat-row.ng-star-inserted.unread td {
    color: #00b2dd;
    cursor: pointer;
}

tr.mat-row.ng-star-inserted.duedateexpiry td {
    color: #EC008C;
    cursor: pointer;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #003F2D;
}

.search_icon_inci {
    position: relative;
    top: 4px;
    left: -10px;
}

.clear_icon_inci {
    position: relative;
    left: -46px;
    top: -20px;
}

.refresh_icon_inci {
    position: relative;
    top: -20px;
    float: right;
}

.padding-40r {
    padding-right: 25px;
}

.padding-40r i {
    margin-right: 5px;
    position: relative;
    top: 5px;
}

.padding-40r i+mat-checkbox {
    position: relative;
    top: 5px;
}

.padding-10l {
    padding-left: 10px;
}

.mat-select-value,
.mat-form-field-infix {
    width:100%!important;
    text-transform: none;
}

.mat-form-field-wrapper {
    padding: 0px !important;
    flex: 1;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 16px!important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    height: 52.5px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0 12px 0!important;
}

.textTranform .mat-select-placeholder {
    text-transform: none !important;
}

.mat-form-field-type-mat-select .mat-form-field-label {
    text-transform: none;
}

label#mat-form-field-label {
    text-transform: none;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    text-transform: none;
}

button:focus {
    outline: none !important;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0px!important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: #003F2D;
}

.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
    background-color: #003F2D !important;
}

.green-snackbar {
    background: #003F2D;
    color: white;
}

.green-snackbar button {
    background-color: #003F2D;
    color: white;
    border: none;
}

.red-snackbar {
    background: #F44336;
    color: white;
}

.red-snackbar button {
    background-color: #F44336;
    color: white !important;
    border: none;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #003F2D;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #003F2D;
}

.mat-radio-group {
    border: 0px!important;
}

.mat-radio-group>.mat-radio-button {
    margin-right: 30px!important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #003F2D;
}

.mat-tab-label .mat-tab-label-content {
    font-size: large!important;
}

.mat-form-field {
    display:flex!important;
    line-height: 1!important;
}

.mat-optgroup-label {
    height: inherit!important;
}

.mat-dialog-container {
    border-radius: 5px!important;
}

.snackbar-container {
    color: white;
    min-width: 60vh!important;
    &.error {
        background-color: #c62828 !important;
    }
    &.success {
        background-color: #003F2D !important;
    }
    &.warning {
        background-color: #ff8f00 !important;
    }
}

.info-snackbar {
    display: flex !important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    background-color: #E7E7F9;
    gap:8px;
    border: 1px solid #262698!important;
    padding: 12px 16px!important;
}

.success-snackbar {
    display: flex !important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    background-color: #E6F4EC;
    gap:8px;
    border: 1px solid #28573C!important;
    padding: 12px 16px!important;
}
.error-snackbar {
    display: flex!important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    background-color: #FBEEEE;
    border: 1px solid #A03530!important;
    padding: 12px 16px!important;
    gap:8px;

}

.warning-snackbar {
    display: flex!important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    background-color: #FFEDDB;
    border: 1px solid #B85F00!important;
    padding: 12px 16px!important;
    gap:8px;
}

@keyframes slideInUp {
    from {
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInUp {
    animation-name: slideInUp;
  }

.confirmation-dialog {
    border-top: 5px solid #ffd740!important;
}

.confirm-dialog-panel {
    width: 500px;
    max-width: 90%;
}

.history-view-dialog {
    width: calc(100% - 180px);
}

.request-access-dialog {
    width: 540px;
}

.request-access-dialog .mat-dialog-container {
    min-width: 200px !important;
}

//button-emerald-style
.glv-button {
    min-width: 80px;
    height: 32px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
    border: solid 2px #003F2D;
    background: #ffffff;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #003F2D;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
    text-transform: uppercase;
    outline: none;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
}

.glv-button:hover {
    background-color: #003F2D;
    border: none;
    color: #fff;
    outline: none;
    cursor: pointer;
}

.glv-button.clicked {
    background-color: #003F2D;
    color: #fff;
    border: solid 2px rgba(0, 0, 0, 0);
    outline: none;
}

.glv-button--primary.clicked {
    border: solid 2px #003F2D;
    background-color: #ffffff;
    color: #003F2D;
    outline: none;
}

.glv-button--primary {
    background-color: #003F2D;
    border: none;
    color: #fff;
    outline: none;
}

.glv-button--primary:hover {
    background-color: #69be28;
    color: #fff;
}

.glv-button--primary .bp--btn__icon {
    fill: #fff;
}

//New Emerald Style buttons
.glv-button-emerald {
    width: 120px;
    height: 36px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #003F2D;
    background: #ffffff;
    font-size: 15px;
    text-align: center;
    color: #003F2D;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
    outline: none;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
    border-radius: 4px;
}

.glv-button-emerald:hover {
    background-color: rgba(0, 63, 45, 0.08);
    border: solid 1px #003F2D;
    color: #003F2D;
    outline: none;
    cursor: pointer;
}

.glv-button-emerald.clicked {
    background-color: #fff;
    color: #003F2D;
    border: solid 2px #003F2D;
    outline: none;
}

.glv-button-emerald--primary {
    background-color: #003F2D;
    border: none;
    color: #fff;
    outline: none;
}

.glv-button-emerald--primary:disabled {
    background: rgba(26, 26, 26, 0.12);
    color: rgba(26, 26, 26, 0.46);
}

.glv-button-emerald--primary:hover:disabled {
    background: rgba(26, 26, 26, 0.12);
    color: rgba(26, 26, 26, 0.46);
    cursor: not-allowed;
    pointer-events: none;
}

.glv-button-emerald--primary.clicked {
    border: solid 2px #003F2D;
    background-color: #003F2D;
    color: #ffffff;
    outline: none;
}

.glv-button-emerald--primary:hover {
    background-color: #003F2D;
    opacity: 0.8;
    color: #fff;
    border: none;
}

.glv-button-emerald--primary .bp--btn__icon {
    fill: #fff;
}

.glv-button--disabled {
    background-color: #dfdfdf;
    border: none;
    color: #fff;
    outline: none;
    pointer-events: none;
}

.glv-button--disabled:hover {
    background-color: #dfdfdf;
    color: #fff;
    cursor: default;
}

#verticalText {
    position: relative;
    top: 224px;
    white-space: nowrap;
    margin-left: 14px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-right: 100%;
    margin-top: 8px;
}

.chipsSection {
    min-height: 20%;
    max-height: 100%;
}

.panelHeader {
    min-height: 20%;
    max-height: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #c2c2c8;
}

.downloadButton {
    width: 208px;
    height: 36px;
    font-weight: 500;
    font-size: 16px;
}

.sizecontainer {
    max-height: 750px;
    overflow: auto;
    width: 90%;
}

.texts {
    font-size: 20px;
    color: #1a1a1a;
    font-weight: 500;
}

.panelH {
    font-weight: bold;
    font-size: 16px;
    margin-top: 8px !important;
}

.footer {
    overflow: auto;
    height: calc(100vh - 166px);
}

.mat-chip>.mat-icon {
    margin-left: 2px !important;
}
.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(255, 255, 255, 0.5);
  }
#mat-header-cell {
    flex-direction: row;
    align-items: center;
    height: 73px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #1A1A1A;
    text-align: end;
}

.mat-chip-list .mat-chip {
    width: auto !important;
    min-width: 0;
    max-height: 4.5em
}

.mat-cell:not(:first-child){
    text-align: left;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    border-right: 1px solid rgba(0, 63, 45, 0.1);
    padding-left: 16px !important;
}

.mat-header-cell.mat-column-headcount, .mat-cell.mat-column-headcount {
    text-align: right!important;
    padding-right: 16px!important;
}

.results-label {
    display: block;
    width: 124px;
    height: 30px;
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: -6px;
    color: rgba(26, 26, 26, 0.6)
}

.example-sidenav-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #f5f7f7
}

.mat-stroked-button:not(.mat-button-disabled) {
    border-color: #003f2d !important;
}

.mat-chip-list-stacked .mat-chip-list-wrapper .mat-standard-chip {
    width: auto !important;
}

.mat-chip-list-stacked .mat-chip-list-wrapper {
    align-items: flex-start;
    flex-direction: row !important;
    margin: 0 !important;
}

.no-border {
    border: 0 !important;
}

.mat-checkbox-layout {
    margin: 0px !important;
}

.mat-paginator-container {
    color:#000;
}
.button-container{ display:flex}
.button { box-sizing: border-box; font-weight: 500; font-size: 16px; line-height: 16px; letter-spacing: -0.02em; padding: 8px 16px; border-radius: 4px; border: 2px solid transparent;}
.mat-button.button, .button { font-size: 13px; line-height: 20px; font-family: 'Space Mono'; border-radius: 25px; padding: 6px 12px; font-weight: 400; border: 1px solid transparent; text-transform: uppercase; }
.button.primary { background: #003f2d; color: #ffffff;}
.button:disabled { cursor: not-allowed !important;}
.button.primary:disabled { background: rgba(26, 26, 26, 0.12) !important; color: rgba(26, 26, 26, 0.46) !important;}
.button.primary:disabled { background: transparent !important; color: rgba(26, 26, 26, 0.46) !important; border: 1px solid #CAD1D399 !important; }
.button.primary:hover { color: #ffffff; background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #003F2D; cursor: pointer;}
.button.primary:disabled:hover { border-color: #CAD1D399; }
.button.primary:focus { color: #ffffff; background: #003f2d; box-shadow: 0px 0px 2px 2px #003f2d;}
.button.primary:focus.no-focus { box-shadow: none }
.button.secondary { color: #003f2d; border: 1px solid #003f2d !important; background: #ffffff;}
.button.secondary:hover { background: rgba(0, 63, 45, 0.08); border-color: #003f2d; cursor: pointer;}
.button.secondary.disabled { color: rgba(26, 26, 26, 0.46) !important; border: 1px solid rgba(26, 26, 26, 0.46) !important; cursor: not-allowed;}
.button.secondary:disabled { color: rgba(26, 26, 26, 0.46) !important; border: 1px solid rgba(26, 26, 26, 0.46) !important; cursor: not-allowed;}
.button.secondary:focus { border: 4px solid #17e88f;}
.button.clear { color: #003f2d; border: 1px solid #003f2d !important; background: transparent; height:36px;}
.button.clear:hover { background: rgba(0, 63, 45, 0.08); border-color: #003f2d; cursor: pointer;}
.button.clear.disabled { color: rgba(26, 26, 26, 0.46) !important; border: 1px solid rgba(26, 26, 26, 0.46) !important; cursor: not-allowed;}
.button.clear:disabled { color: rgba(26, 26, 26, 0.46) !important; border: 1px solid rgba(26, 26, 26, 0.46) !important; cursor: not-allowed;}
.button.clear:focus { border: 4px solid #17e88f; opacity: 0.6;}
.button.icon-button{padding:0px;}
.button.text { color: #003f2d; border: none; background: none; height:36px;}
.button.text:hover { color: #003f2d; background: #003f2d14;}
.button.text.disabled { color: #1a1a1a46 !important; border: none !important} 
.button.text:focus { color: #003f2d; border: 2px solid #003f2d; background: #ffffff;}
.button.light { color: #003f2d; border: 1px solid transparent;}
.button.light:hover { color: #003f2d; background: #1a1a1a04;}
.button.light.disabled { color: #1a1a1a46;}
.button.light:focus { color: #28ffc2; border: 1px solid #003f2d;}
.button.text:disabled .icon-style{color: #1a1a1a46 !important}
.mat-label-background { background: white; padding-right: 3px; }
.removefocus{  border: inherit!important; opacity: 1!important; }
.removeflex {flex: none !important}
.button-icon {
    height: 36px;
    width: 36px;
    border: 1px solid #003f2d;
    padding: 5px;
    box-sizing: border-box;
    background-color: #DFE6E5;
    border-radius: 4px;
    outline: none;

    &:disabled,
    &:disabled:hover {
        background-color: rgba(26, 26, 26, 0.12);
        border: none;
    }
    &:disabled .icon-style {
        color: rgba(26, 26, 26, 0.12);
        cursor: not-allowed;
    }
}
.button-icon:disabled { cursor: not-allowed; background: rgba(0,63,45,.0784313725); }
.button-icon:hover { background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)),#003f2d }
.button-icon:focus, .button-icon.active { outline: 1px solid #003f2d; border: 1px solid #FFFFFF; background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)),#003f2d; color: #FFFFFF; }

.button-icon:hover svg path, .button-icon:focus svg path, .button-icon.active svg path { fill: #FFFFFF; }
// .button-icon:focus svg path, .button-icon.active svg path { fill: #0B0E0E; }

.mat-dialog-actions .mat-button-base+.mat-button-base { margin-left: 0px!important }
.dialog{ display:flex; flex-direction: column; position:relative; gap:16px; }
.modal-header{ font-style: normal; font-weight: 500; font-size: 20px; margin: 0px !important; display: flex !important; padding:0; border:0}
.close-button{ position: absolute !important; top:-20px; right:-20px;}
.mat-button-focus-overlay { opacity: 0!important; }
.mat-tab-label{ opacity: 1 !important;; padding:0px!important; }
.mat-tab-label .mat-tab-label-content { white-space: break-spaces!important;}
.loader {
    background: 0 0/200px 100% no-repeat rgba(26, 26, 26, 0.12) !important;
    border-radius: 20px;
    width: 96%;
    height: 15px;
    margin-bottom: 0px !important;
    margin-top: 5px;
}

.headline-h3 {
    font-family: 'Financier Display' !important;
    font-size: 50px;
    font-weight: 400;
    line-height: 56px;
    color: rgba(26, 26, 26, 1);
}

.typography-1 {
    font-family: Calibre-R;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(26, 26, 26, 1);
}

.loader.circle {
    background: 0 0/200px 100% no-repeat rgba(26, 26, 26, 0.12) !important;
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 50% !important;
}

.mat-tooltip {
    // to make possible place arrow pseudo element outside tooltip with absolute positioning
    overflow: visible;
    position: relative;
    &.right {
        border-left: 6px solid red;
        margin-left: 5px;
        &::before {
            position: absolute;
            content: '';
            display: inline-block;
            background-color: red;
            clip-path: polygon(50% 0, 0 50%, 50% 100%);
            left: -12px;
            width: 15px;
            height: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


// commom styles for side nav
.sidenav-container {
    height: calc( 100vh - 62px);
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.sidenav-content {
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 15px;
}

.sidenav {
    user-select: none;
    width: 404px;
}

.verticalSideNav {
    width: 50px;
}

.sidenav-full-widt {
    width: 100%;
}

.sidenav-menu-button {
    transition: 300ms ease-in-out;
    transform: rotate(0deg);
}

.sidenav-menu-button.rotated {
    transform: rotate(180deg);
}

.sidenavsubmenu {
    overflow-y: hidden;
    transition: transform 300ms ease;
    transform: scaleY(0);
    transform-origin: top;
    padding-left: 30px;
}

.sidenavsubmenu.expanded {
    transform: scaleY(1);
}
.invalid-feedback {
    display: flex !important;
    padding-left:20px;
}
// panel class for lag-single-select
.panelClass, .panelMultipleClass {
    margin-top: 33px;
    margin-left: 5px;
    border-radius: 0px;
}
.panelMultipleClass { margin-left: 30px;}
.landingPanelClass { margin-top: 40px;}
.marker-globe {
    background-image: url('/assets/images/marker-globe.svg');
    width: 32px;
    height: 32px;
    cursor: pointer;
  
    &:hover {
      width: 56px;
      height: 56px;
      background-image: url('/assets/images/marker-globe-hover.svg');
    }
  }
svg > .highcharts-tooltip-hide {
    display: none;
}
.highlight{
    background-color: mark;
    padding: 0 !important;
}

.subtitle-1 {
    font-family: Calibre-R;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #262626;
}

.subtitle-2 {
    font-family: Calibre-R;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #262626;
}

.typography-2 {
    font-family: Calibre-R;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;  
}
.mt-24 {
    margin-top: 24px;
}

body {
    &.dark {
        .button.primary {
            background-color: #0B0E0E;
            border-color: #17E88F;
            color: #FFFFFF;
        }
        .button.primary:hover {
            background-color: #17E88F;
        }
        .button.secondary {
            color: #0B0E0E;
            border: 1px solid #17E88F !important;
        }
        .button-icon svg path, .button-icon:hover svg path { fill: #17E88F; }
        .button-icon:focus, .button-icon.active {
            outline: none;
            border: 1px solid #17E88F;
            background: linear-gradient(180deg, 
            rgba(202, 209, 211, 0.49), 
            rgba(202, 209, 211, 0.13));
        }
        .button-icon { border: 1px solid #17E88F; background-color: transparent; }
  }
}

  .page-title-layout {
    h1 {
      font-size: 50px; 
      color: #1a1a1a;
      font-weight: 400;
      font-family: "Financier Display";
    }
    
    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
        
        &__left {
            display: flex;
            gap: 10px; 
        }

        &__right {
            display: flex;
            gap: 10px; 
        }
    }

  }